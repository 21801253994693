import React from 'react';
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  Link,
  Box,
  IconButton,
} from '@mui/material';
import GenplsIcon from '../assets/genpls_logo.png';

const Header: React.FC = () => {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleScrollTo = (targetId: string) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const topPosition = targetElement.offsetTop - 100;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    } else {
      window.location.href = `/#${targetId}`;
    }
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'primary.main' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleLogoClick}
          sx={{ bgcolor: 'white' }}
        >
          <img
            src={GenplsIcon}
            alt="Genpls Logo"
            style={{ width: '40px', height: '40px' }}
          />
        </IconButton>
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          component="nav"
        >
          <List className="nav navbar-nav">
            <ListItem className="top-header-font" sx={{ display: 'inline' }}>
              <Link
                className="underLine-animation"
                onClick={() => handleScrollTo('reservation')}
                color="textSecondary"
                style={{ cursor: 'pointer' }}
              >
                ご予約 ＆ スケジュール
              </Link>
            </ListItem>
            <ListItem className="top-header-font" sx={{ display: 'inline' }}>
              <Link
                className="underLine-animation"
                onClick={() => handleScrollTo('service')}
                color="textSecondary"
                style={{ cursor: 'pointer' }}
              >
                WakU2の設備
              </Link>
            </ListItem>
            <ListItem className="top-header-font" sx={{ display: 'inline' }}>
              <Link
                className="underLine-animation"
                onClick={() => handleScrollTo('price')}
                color="textSecondary"
                style={{ cursor: 'pointer' }}
              >
                利用料金
              </Link>
            </ListItem>
            <ListItem className="top-header-font" sx={{ display: 'inline' }}>
              <Link
                className="underLine-animation"
                onClick={() => handleScrollTo('access')}
                color="textSecondary"
                style={{ cursor: 'pointer' }}
              >
                アクセス
              </Link>
            </ListItem>
            <ListItem className="top-header-font" sx={{ display: 'inline' }}>
              <Link
                className="underLine-animation"
                onClick={() => handleScrollTo('contact')}
                color="textSecondary"
                style={{ cursor: 'pointer' }}
              >
                お問い合わせ
              </Link>
            </ListItem>
          </List>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
