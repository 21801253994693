import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const ReservationSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        ご予約 & スケジュール
      </Typography>
      <Typography style={{ marginBottom: '1rem' }}>
        <Link
          href="https://spacemarket.com/p/iWe0aXKX5dJzRyL8"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </Link>
        よりご予約ください。
      </Typography>
      <Typography>
        ※
        毎月第１木曜日または第２木曜日にひまわりビルのエレベーター点検が予定されております。
      </Typography>
      <Typography>
        11:00~12:00の点検時間はエレベーターが使用できないためご予約を受け付けておりません。
      </Typography>
      <Typography style={{ marginBottom: '1rem' }}>
        点検時間内エレベーターご使用不可にご了承を頂けましたら点検時間を含んだご予約を承りますので、その場合はご連絡下さい。
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <iframe
          src="https://calendar.google.com/calendar/embed?src=waku2%40genpls.com&ctz=Asia%2FTokyo"
          style={{ border: 0 }}
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </Box>
    </Box>
  );
};

export default ReservationSection;
