import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import HimawariImage from '../assets/building.jpg';
import { GoldenRatioImage, accessMapStyle } from '../styles/commonStyles';

const AccessSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        アクセス
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>所在地</TableCell>
            <TableCell>
              <List>
                <ListItem>
                  〒192-0085 東京都八王子市中町９−１０ ひまわりビル7階
                </ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>最寄駅</TableCell>
            <TableCell>
              <List>
                <ListItem>
                  JR中央線（横浜線, 八高線）『八王子駅』北口 徒歩5分
                </ListItem>
                <ListItem>京王線『京王八王子駅』徒歩10分</ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={HimawariImage} alt="Himawari building" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={accessMapStyle}>
            <Box
              component="iframe"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12966.958692460048!2d139.335229!3d35.658785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191e77fc30f593%3A0x1610bdebb9768bcf!2z5YWr546L5a2Q44Os44Oz44K_44Or44K544Oa44O844K5V2FrVTI!5e0!3m2!1sja!2sjp!4v1706665683685!5m2!1sja!2sjp"
              width="100%"
              height="100%"
              style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
              allowFullScreen
              title="WakU2 Access Map"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccessSection;
