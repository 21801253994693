import React from 'react';
import { Box, Typography, Grid, Paper, Link } from '@mui/material';
import MadoriImage from '../assets/madori.jpg';
import WhiteBoardImage from '../assets/white_board.jpg';
import ProjectorImage from '../assets/projector.jpg';
import LockerImage from '../assets/locker.jpg';
import AirConditioningImage from '../assets/air_conditioning.jpg';
import RestRoomImage from '../assets/rest_room.jpg';
import PowerStrip1Image from '../assets/power_strip_1.jpg';
import PowerStrip2Image from '../assets/power_strip_2.jpg';
import MultifunctionDeviceImage from '../assets/multifunction_device.jpg';
import WifiImage from '../assets/wifi.png';
import EntranceImage from '../assets/entrance.jpg';
import { GoldenRatioImage } from '../styles/commonStyles';

const ServiceSection: React.FC = () => {
  const handleScrollTo = (targetId: string) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const topPosition = targetElement.offsetTop - 100;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    } else {
      window.location.href = `/#${targetId}`;
    }
  };

  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        WakU2の設備
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">
            スペース全体図
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <img
              src={MadoriImage}
              alt="Madori"
              style={{ width: '100%', marginBottom: '1rem' }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={WhiteBoardImage} alt="WhiteBoard" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            ホワイトボード（2台）
          </Typography>
          <Typography paragraph>
            ８５８mm × １７５８mm（板面有効 高さ×幅）
          </Typography>
          <Typography paragraph>
            ８６７mm × １１６７mm（板面有効 高さ×幅）
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            プロジェクター
          </Typography>
          <Typography paragraph>
            接続は、HDMI・VGA・USBに対応しております。
          </Typography>
          <Typography paragraph>
            プロジェクターはホワイトボードに投影していただいてのご利用となりますので、あらかじめご了承ください。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={ProjectorImage} alt="Projector" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={LockerImage} alt="Locker" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            ロッカー
          </Typography>
          <Typography paragraph>
            ８５８mm × １７５８mm（板面有効 高さ×幅）
          </Typography>
          <Typography paragraph>
            ８６７mm × １１６７mm（板面有効 高さ×幅）
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            照明・空調
          </Typography>
          <Typography paragraph>
            スペースにお入りいただきすぐ右側にございます。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage
              src={AirConditioningImage}
              alt="AirConditioning"
            />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={WifiImage} alt="Wifi" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Wi-Fi
          </Typography>
          <Typography paragraph>
            机上にWi-FiのIDとパスワードが書かれたカードがありますので、そちらをご覧ください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            トイレ
          </Typography>
          <Typography paragraph>スペース右奥に1台ございます。</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={RestRoomImage} alt="RestRoom" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={PowerStrip1Image} alt="PowerStrip" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            電源
          </Typography>
          <Typography paragraph>
            フロアの床に数箇所電源がございます。
          </Typography>
          <Typography paragraph>
            矢印の方に引くと中から電源が出てきますのでご利用ください。
          </Typography>
          <Typography paragraph>ご使用後は元に戻して下さい。</Typography>
          <Typography paragraph>
            戻す際は、出した時同様、矢印の方に引きながらお戻し下さい。
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            電源タップ
          </Typography>
          <Typography paragraph>ご自由にお使いください。</Typography>
          <Typography paragraph>
            使い終わりましたらコードをまとめてください。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={PowerStrip2Image} alt="PowerStrip" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage
              src={MultifunctionDeviceImage}
              alt="MultifunctionDevice"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            複合機
          </Typography>
          <Typography paragraph>
            A3・A4・B4・B5の用紙のご用意があります。
          </Typography>
          <Typography paragraph>
            <Link
              onClick={() => handleScrollTo('price')}
              style={{ cursor: 'pointer' }}
            >
              ご利用料金
            </Link>
            をご確認ください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            その他
          </Typography>
          <Typography paragraph>● 冷蔵庫はご利用いただけません。</Typography>
          <Typography paragraph>
            ●
            出ましたゴミ（紙コップやコーヒーのインサートカップ等）はコピー機横のゴミ箱をご利用ください。
          </Typography>
          <Typography paragraph>
            ●
            テーブル等が汚れましたら、ウェットティッシュ等でセルフクリーニングをお願いいたします。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={EntranceImage} alt="Entrance" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
