import React from 'react';
import {
  Box,
  Typography,
  //Link,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from '@mui/material';

const PriceSection: React.FC = () => {
  const plans = [
    {
      name: '平日利用プラン',
      //price: '¥4,800/時間（税込）',
      price: '¥5,751/時間（税込）',
      features: ['平日AM8時〜PM10時の間', '2時間以上からご利用可能'],
    },
    {
      name: '土日祝日プラン',
      //price: '¥5,300/時間（税込）',
      price: '¥6,329/時間（税込）',
      features: ['土日祝日AM8時〜PM10時の間', '2時間以上からご利用可能'],
    },
    //{
    //  name: '平日1日貸切プラン',
    //  price: '¥57,600（税込）',
    //  features: ['平日AM8時〜PM10時まで貸切'],
    //},
    //{
    //  name: '土日祝日1日貸切プラン',
    //  price: '¥63,600（税込）',
    //  features: ['土日祝日AM8時〜PM10時まで貸切'],
    //},
    {
      name: 'コピー・プリント（白黒）',
      price: '¥10/枚（税込）',
      features: [],
    },
    {
      name: 'コピー・プリント（カラー）',
      price: '¥50/枚（税込）',
      features: [],
    },
  ];

  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        利用料金
      </Typography>
      <Grid>
        <Grid container spacing={3}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    {plan.name}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    {plan.price}
                  </Typography>
                  <List>
                    {plan.features.map((feature, idx) => (
                      <ListItem key={idx}>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PriceSection;
