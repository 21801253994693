import React, { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import HomeSection from '../components/HomeSection';
import ReservationSection from '../components/ReservationSection';
import ServiceSection from '../components/ServiceSection';
import PriceSection from '../components/PriceSection';
import AccessSection from '../components/AccessSection';
import ContactSection from '../components/ContactSection';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HomePage: React.FC = () => {
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        const topPosition = targetElement.offsetTop - 100;
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
      <Header />
      <Box id="home" width="100%" height="100vh">
        <HomeSection />
      </Box>
      <Container maxWidth="md">
        <Box mt={8}>
          <Box id="reservation">
            <ReservationSection />
          </Box>
          <Box id="service">
            <ServiceSection />
          </Box>
          <Box id="price">
            <PriceSection />
          </Box>
          <Box id="access">
            <AccessSection />
          </Box>
          <Box id="contact">
            <ContactSection />
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default HomePage;
